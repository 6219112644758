import React from 'react';
import { MultiLang } from '../config';
import styles from './Footer.module.css';

interface Props {
    lang: MultiLang;
}

const Footer = (props: Props) => {
    return (
        <footer className={styles.footer}>
            <div className={styles.copyright}>
                &copy; 2007 Neuroinformatics Laboratory, RIKEN BSI.
            </div>
        </footer>
    );
}

export default Footer;
