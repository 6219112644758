import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Database from '../database/Database';
import DatabaseXoopsPathRedirect from '../database/DatabaseXoopsPathRedirect';
import About from './About';
import styles from './MainContent.module.css';
import XoopsPathRedirect from './XoopsPathRedirect';
import { MultiLang } from '../config';

interface Props {
    lang: MultiLang;
}

const MainContent = (props: Props) => {
    const { lang } = props;
    return (
        <div className={styles.mainContent}>
            <Switch>
                <Route path="/database" render={() => <Database lang={lang} />} />
                <Route exact path="/about" component={About} />
                <Route path="/modules/xoonips" render={() => <DatabaseXoopsPathRedirect lang={lang} />} />
                <Route component={XoopsPathRedirect} />
            </Switch>
        </div>
    );
}

export default MainContent;