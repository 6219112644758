import React from 'react';
import { MultiLang } from '../config';
import IndexTree from '../database/blocks/IndexTree';
import Search from '../database/blocks/Search';
import MainMenu from './blocks/MainMenu';
import styles from './LeftColumn.module.css';

interface Props {
    lang: MultiLang;
}

const LeftColumn = (props: Props) => {
    const { lang } = props;
    return (
        <td className={styles.leftColumn}>
            <div className={styles.leftBlock}>
                <h2 className={styles.leftBlockTitle}>Main Menu</h2>
                <div className={styles.leftBlockContent}>
                    <MainMenu lang={lang} />
                </div>
            </div>
            <div className={styles.leftBlock}>
                <h2 className={styles.leftBlockTitle}>Search</h2>
                <div className={styles.leftBlockContent}>
                    <Search lang={lang} />
                </div>
            </div>
            <div className={styles.leftBlock}>
                <h2 className={styles.leftBlockTitle}>Index Tree</h2>
                <div className={styles.leftBlockContent}>
                    <IndexTree lang={lang} />
                </div>
            </div>
        </td>
    );
}

export default LeftColumn;
