import React from 'react';
import { MultiLang } from '../config';
import CenterColumn from './CenterColumn';
import styles from './Container.module.css';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';

interface Props {
    lang: MultiLang;
}

const Container = (props: Props) => {
    const { lang } = props;
    return (
        <div className={styles.container}>
            <table className={styles.wrapper}>
                <tbody>
                    <tr>
                        <LeftColumn lang={lang} />
                        <CenterColumn lang={lang} />
                        <RightColumn lang={lang} />
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Container;