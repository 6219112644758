import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { MultiLang } from '../config';
import DatabaseTop from '../database/DatabaseTop';
import styles from './CenterColumn.module.css';
import MainContent from './MainContent';

interface Props {
    lang: MultiLang;
}

const Database = () => {
    return (<>&nbsp;&raquo;&nbsp;Database</>);
}
const About = () => {
    return (<>&nbsp;&raquo;&nbsp;About</>);
}

const BreadClumbs = () => {
    return (
        <div className={styles.breadclumbs}>
            <Link to="/">Home</Link>
            <Switch>
                <Route path="/database" component={Database} />
                <Route path="/about" component={About} />
            </Switch>
        </div >
    );
}

const CenterBlocks = (props: Props) => {
    const { lang } = props;
    return (
        <table className={styles.centerBlocks}>
            <tbody>
                <tr>
                    <td colSpan={2}>
                        <h2 className={styles.centerMainTitle}>Registered Itemtypes</h2>
                        <div className={styles.centerMainContent}>
                            <DatabaseTop lang={lang} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className={styles.centerLeftColumn}>
                    </td>
                    <td className={styles.centerRightColumn}>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

const CenterColumn = (props: Props) => {
    const { lang } = props;

    return (
        <td className={styles.centerColumn}>
            <BreadClumbs />
            <Switch>
                <Route exact path="/" render={() => <CenterBlocks lang={lang} />} />
            </Switch>
            <MainContent lang={lang} />
        </td>
    );
}

export default CenterColumn;