import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import styles from './MainMenu.module.css';

interface Props {
    lang: MultiLang;
}

const MainMenu = (props: Props) => {
    return (
        <ul className={styles.mainmenu}>
            <li><Link className={styles.menuTop} to="/">Home</Link></li>
            <li><Link className={styles.menuMain} to="/database">Database</Link></li>
            <li><Link className={styles.menuMain} to="/about">About</Link></li>
        </ul>
    );
}

export default MainMenu;
