const SITE_TITLE = 'Pupil Platform';
const SITE_SLOGAN = 'Home';
const GOOGLE_ANALYTICS_TRACKING_ID = '';
const XOONIPS_ITEMTYPES = ['conference', 'paper', 'book', 'data', 'model', 'url'];

export type MultiLang = 'en' | 'ja';

const Config = {
    SITE_TITLE,
    SITE_SLOGAN,
    GOOGLE_ANALYTICS_TRACKING_ID,
    XOONIPS_ITEMTYPES,
}

export default Config;
