import jsonp from 'jsonp';
import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import styles from './FlickrBadge.module.css';

interface FlickrServicesFeedsPhotosPublicItem {
    title: string;
    link: string;
    media: {
        m: string;
    },
    data_taken: string;
    description: string;
    published: string;
    author: string;
    author_id: string;
    tages: string;
}
interface FlickrServicesFeedsPhotosPublic {
    title: string;
    link: string;
    description: string;
    modified: string;
    generator: string;
    items: FlickrServicesFeedsPhotosPublicItem[];
}

const FLICKR_FEED_URL = 'https://api.flickr.com/services/feeds/photos_public.gne';

const FlickrBadgeItem = (props: { item: FlickrServicesFeedsPhotosPublicItem }) => {
    const { item } = props;
    return (
        <div className={styles.item}>
            <a href={item.link} title={item.title} target="_blank" rel="noopener noreferrer">
                <img className={styles.thumbnail} src={item.media.m} alt={item.title} />
            </a>
        </div>
    );
}

interface Props {
    lang: MultiLang;
    tags: string;
}
interface State {
    items: JSX.Element[];
    showWhat: boolean;
    active: boolean;
}

class FlickrBadge extends Component<Props, State> {

    private interval: NodeJS.Timeout | null = null;
    private data: FlickrServicesFeedsPhotosPublic | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            items: [],
            showWhat: false,
            active: false,
        };
        this.handleClickWhat = this.handleClickWhat.bind(this);
    }

    componentDidMount() {
        const params = new URLSearchParams({
            tags: this.props.tags,
            format: 'json',
        });
        const url = FLICKR_FEED_URL + '?' + params.toString();
        jsonp(url, { name: 'jsonFlickrFeed' }, (err, data: FlickrServicesFeedsPhotosPublic) => {
            if (err) {
                console.log('failed to get flickr resource.');
                return;
            }
            if (typeof data !== 'undefined') {
                this.data = data;
                this.updateImage(true);
                this.interval = setInterval(() => { this.updateImage(false) }, 60000);
            }
        });
    }

    componentWillUnmount() {
        if (this.interval === null) {
            return;
        }
        clearInterval(this.interval);
    }

    updateImage(first: boolean) {
        if (this.data === null) {
            return;
        }
        let items: JSX.Element[] = [];
        let nums: number[] = [];
        const max = Math.min(3, this.data.items.length);
        while (nums.length < max) {
            const i = Math.floor(Math.random() * (this.data.items.length));
            if (nums.includes(i)) {
                continue;
            }
            nums.push(i);
            const item = <FlickrBadgeItem key={i} item={this.data.items[i]} />;
            items.push(item);
        }
        if (first) {
            this.setState({ active: true, items });
        } else {
            this.setState({ active: false });
            setTimeout(() => { this.setState({ active: true, items }) }, 1000);
        }
    }

    handleClickWhat(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ showWhat: true });
    }

    render() {
        const url = 'https://www.flickr.com';
        const tags = this.props.tags.replace(',', ' ');
        const tagsLabel = this.props.tags.replace(',', ' and ');
        const tagsUrl = url + '/photos/tags/' + Functions.escape(tags);
        return (
            <div className={styles.flickr}>
                <div className={styles.badge}>
                    <div className={styles.header}>
                        <a href={url} target="_blank" rel="noopener noreferrer">www.<span style={{ fontWeight: 'bold', color: '#3993ff' }}>flick<span style={{ color: '#ff1c92' }}>r</span></span>.com</a>
                    </div>
                    <div className={styles.frame}>
                        <CSSTransition in={this.state.active} timeout={1000} className="items">
                            <div>{this.state.items}</div>
                        </CSSTransition>
                    </div>
                    <div className={styles.footer}>
                        {this.state.showWhat ||
                            <a href={url} onClick={this.handleClickWhat}>what is this?</a>
                        }
                        {this.state.showWhat &&
                            <span>This is a Flickr badge showing public photos from Flickr tagged with <a href={tagsUrl} target="_blank" rel="noopener noreferrer">{tagsLabel}</a>.</span>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default FlickrBadge;