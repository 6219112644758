import React from 'react';
import { MultiLang } from '../config';
import FlickrBadge from '../custom/blocks/FlickrBadge';
import styles from './RightColumn.module.css';

interface Props {
    lang: MultiLang;
}

const RightColumn = (props: Props) => {
    const { lang } = props;
    return (
        <td className={styles.rightColumn}>
            <div className={styles.rightBlock}>
                <h2 className={styles.rightBlockTitle}>Eye Photos</h2>
                <div className={styles.rightBlockContent}>
                    <FlickrBadge tags="eye,pupil" lang={lang} />
                </div>
            </div>
        </td>
    );
}

export default RightColumn;