import React, { Component } from 'react';
import { ReactCookieProps, withCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Config, { MultiLang } from '../config';
import Functions from '../functions';
import Container from './Container';
import Footer from './Footer';
import Header from './Header';


interface Props extends RouteComponentProps, ReactCookieProps { }
interface State {
    lang: MultiLang;
}

class AppRoot extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { lang: 'en' };
        if (Config.GOOGLE_ANALYTICS_TRACKING_ID !== '') {
            ReactGA.initialize(Config.GOOGLE_ANALYTICS_TRACKING_ID);
        }
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const params = new URLSearchParams(nextProps.location.search);
        const param_lang = params.get('ml_lang');
        const cookie_lang = typeof nextProps.cookies !== 'undefined' ? nextProps.cookies.get('ml_lang') : null;
        let lang = param_lang || (typeof cookie_lang === 'string' ? cookie_lang : null) || prevState.lang;
        if (lang !== 'en' && lang !== 'ja') {
            lang = 'en';
        }
        if (cookie_lang !== lang) {
            if (typeof nextProps.cookies !== 'undefined') {
                nextProps.cookies.set('ml_lang', lang, { path: '/' });
            }
        }
        if (prevState.lang !== lang) {
            return { lang };
        }
        return null;
    }

    componentDidMount() {
        const { pathname } = this.props.location;
        if (Config.GOOGLE_ANALYTICS_TRACKING_ID !== '') {
            ReactGA.set({ page: pathname });
            ReactGA.pageview(pathname);
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { pathname, search } = this.props.location;
        if (Config.GOOGLE_ANALYTICS_TRACKING_ID !== '') {
            ReactGA.set({ page: pathname });
            ReactGA.pageview(pathname);
        }
        if (pathname !== prevProps.location.pathname || search !== prevProps.location.search) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        const { lang } = this.state;
        return (
            <>
                <Helmet>
                    <title>{Functions.siteTitle(lang)} - {Functions.siteSlogan(lang)}</title>
                </Helmet>
                <Header lang={lang} />
                <Container lang={lang} />
                <Footer lang={lang} />
            </>
        );
    }
}

export default withCookies(withRouter(AppRoot));
