import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../config';
import logo from './assets/images/theme/pupil_logo.jpg';
import styles from './Header.module.css';

interface Props {
    lang: MultiLang;
}

const Header = (props: Props) => {
    return <header className={styles.header}>
        <h1 className={styles.h1}><Link to="/"><img className={styles.img} src={logo} alt="Pupil platform" title="Communication platform for pupil researchers" /></Link></h1>
    </header>;
}

export default Header;
