import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './About.module.css';

const About = () => {
    return (
        <div>
            <Helmet>
                <title>About - Pupil Platform</title>
            </Helmet>
            <h1>About</h1>
            <p className={styles.notice}>
                Notice: This site has been archived since May 2019 and is no longer updated.
            </p>
            <h2 className={styles.title}>Pupil Platform</h2>
            <div className={styles.content}>
                <p>
                    The Pupil Platform has been developed by Laboratory for Neuroinfomatics, RIKEN Brain Science Institute in 2007.
                    Which is the one of the practical applications of <a href="https://xoonips.osdn.jp" target="_blank" rel="noopener noreferrer">XooNIps base platform system</a>.
                    For more inforamtion, see related publication below.
                </p>
            </div>
            <h2 className={styles.title}>Related Publication</h2>
            <div className={styles.content}>
                Customizable neuroinformatics database system: XooNIps and its application to the pupil platform. Yamaji K, Sakai H, Okumura Y, Usui S
Comput Biol Med. 37(7) 1036-1041 Jul 2007
                <br />
                <a href="https://doi.org/10.1016/j.compbiomed.2006.09.003" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.compbiomed.2006.09.003</a>
            </div>
        </div>
    );
}

export default About;